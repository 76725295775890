<div class="col col-1">
  <div class="content content-users">
    <div class="users-filter">
      <app-form-field
        class="field"
        [type]="'select-group'"
        label="Filtros"
        [fieldControl]="usersFilter | asFormControl"
        [optionsGroup]="userFiltersOptions"
      />
    </div>
    <div class="users-search">
      <app-form-field
        class="field"
        [type]="'text'"
        label="Búsqueda"
        [fieldControl]="usersSearch | asFormControl"
      />
    </div>

    <mat-tab-group (selectedIndexChange)="tabIndexChange($event)">
      <mat-tab label="Quantum">
        <ul class="users" *ngIf="!loadingUsers && users.length">
          <li 
            class="user" 
            *ngFor="let user of filteredUsers; let i = index" 
            [ngClass]="{ 
              'selected': isUserSelected(user)
            }"
            (click)="setUser(user)"
          >
            <lazy-img 
              class="avatar"
              [source]="user.value.imagePath"
              [alt]="user.label"
              [contain]="false"
              (onError)="getDefaulImg(user)"
            >
            </lazy-img>
    
            <div class="data">
              <span class="name">{{ user.label }}</span>
              <span class="uid">{{ user.value.uid }}</span>
    
              <div class="roles">
                <ng-container>
                  <ng-container *ngFor="let role of user.value.roles | slice:0:2">
                    <div class="role-label admin" *ngIf="role === UserRole.Admin">
                      Admin
                    </div>
                    <div class="role-label other" *ngIf="role !== UserRole.Admin">
                      {{getUserRole(role)}}
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
    
            <div class="right">
              <div class="check" (click)="toggleUserCheck($event, user)">
                <mat-icon *ngIf="!user.otherData">circle</mat-icon>
                <mat-icon class="checked" *ngIf="user.otherData === 'checked'">circle</mat-icon>
              </div>
              <div class="chevron">
                <mat-icon>chevron_right</mat-icon>
              </div>
            </div>
          </li>
          <li 
            class="item institution no-data" 
            *ngIf="!filteredUsers.length" 
          >
            Ningún usuario encontrado
          </li>
        </ul>
      </mat-tab>
      <mat-tab label="BI">
        <ul class="users bi-uers" *ngIf="!loadingUsers && biUsers.length">
          <li 
            class="user" 
            *ngFor="let user of filteredBiUsers; let i = index" 
            [ngClass]="{ 
              'selected': isUserSelected(user)
            }"
            (click)="setUser(user)"
          >
            <lazy-img 
              class="avatar"
              source="/assets/images/logo_bi.png"
              [alt]="user.label"
              [contain]="false"
            >
            </lazy-img>
    
            <div class="data">
              <span class="name">{{ user.label }}</span>
            </div>
    
            <div class="right">
              <div class="edit">
                <button (click)="editBiUser($event, user)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
              <div class="check" (click)="toggleUserCheck($event, user)">
                <mat-icon *ngIf="!user.otherData">circle</mat-icon>
                <mat-icon class="checked" *ngIf="user.otherData === 'checked'">circle</mat-icon>
              </div>
              <div class="chevron">
                <mat-icon>chevron_right</mat-icon>
              </div>
            </div>
          </li>
          <li 
            class="item institution no-data" 
            *ngIf="!filteredBiUsers.length" 
          >
            Ningún usuario encontrado
          </li>
        </ul>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="content content-new">
    <div class="option-button" >
      <button 
        mat-button class="action" 
        title="Crear usuario BI" 
        (click)="newUser()"
      >
        <mat-icon>add</mat-icon>
        <span>NUEVO USUARIO BI</span>
      </button>
    </div>
  </div>
</div>

<div class="col col-2">
  <div class="content content-user" [ngClass]="{'disabled': !(userSelected())}">
    <ng-container *ngIf="userSelected()">
      <span class="name">{{form.get('user')?.value.nam}}</span>
    </ng-container>
  </div>
  
  <div class="content content-provinces" [ngClass]="{'disabled': !(userSelected())}">
    <div class="province-search">
      <app-form-field
        class="field"
        [type]="'text'"
        label="Búsqueda"
        [fieldControl]="provincesSearch | asFormControl"
      />
    </div>

    <ul class="item-list provinces">
      <ng-container *ngIf="userSelected()">
        <li 
          class="item province" 
          *ngFor="let province of filteredProvinces; let i = index" 
          [ngClass]="{ 
            'selected': isProvinceSelected(province),
          }"
          (click)="setProvince(province)"
        >
          <span class="name">{{ province.label }}</span>

          <div class="check" (click)="toggleProvinceCheck($event, province)">
            <mat-icon *ngIf="!province.otherData">check_box_outline_blank</mat-icon>
            <mat-icon class="indeterminate" *ngIf="province.otherData === 'indeterminate'">indeterminate_check_box</mat-icon>
            <mat-icon class="checked" *ngIf="province.otherData === 'checked'">check_box</mat-icon>
          </div>
          <div class="chevron">
            <mat-icon>chevron_right</mat-icon>
          </div>
        </li>
        <li 
          class="item institution no-data" 
          *ngIf="!filteredProvinces.length" 
        >
          Ninguna provincia encontrada
        </li>
      </ng-container>
    </ul>
  </div>
</div> 

<div class="col col-3">
  <div class="content" [ngClass]="{'disabled': !(userSelected() && provinceSelected())}">
    <div class="institution-search">
      <app-form-field
        class="field"
        [type]="'text'"
        label="Búsqueda"
        [fieldControl]="institutionsSearch | asFormControl"
      />
    </div>

    <ul class="item-list institutions">
      <ng-container *ngIf="userSelected() && provinceSelected()">
        <li 
          class="item institution" 
          *ngFor="let institution of filteredInstitutions; let i = index" 
        >
          <span class="name">{{ institution.label }}</span>

          <div class="check" (click)="toggleInstitutionCheck($event, institution)">
            <mat-icon *ngIf="!institution.otherData">check_box_outline_blank</mat-icon>
            <mat-icon *ngIf="institution.otherData === 'checked'" class="checked">check_box</mat-icon>
          </div>
        </li>
        <li 
          class="item institution no-data" 
          *ngIf="!filteredInstitutions.length" 
        >
          Ninguna institución encontrada
        </li>
      </ng-container> 
    </ul>
  </div>
</div>
export const environment = {
  production: true,
  local: false,
  urlBack: 'https://apipre.globaleye.es/',
  urlWS: 'wss://apipre.globaleye.es',
  urlBI: 'https://bipre.globaleye.es/',
  client_id: '',
  client_secret: '',

  xiltec_ai_assistant_url: 'https://ai-globaleye.xiltec.es',
  xiltec_ai_assistant_key: '70354097-9fbf-4811-928b-9c49046c54fa',
  
  ga_quantum: 'G-LJ9ME9GTKC',
  ga_pharma: 'G-HCGS7FZ595',
};
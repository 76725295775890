import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SelectInstitutionForm } from '@interfaces/forms.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { InstitutionService } from '@services/institution.service';
import { FormFieldComponent } from '@shared/form-field/form-field.component';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-select-institution',
    templateUrl: './select-institution.component.html',
    styleUrls: ['./select-institution.component.scss'],
})
export class SelectInstitutionComponent implements OnInit, OnDestroy {

    @ViewChild('institutionField') institutionField: FormFieldComponent;

    public form: FormGroup<SelectInstitutionForm> = new FormGroup({
        institution: new FormControl(null, { validators: Validators.required }),
    });

    public farmacyOptions: SelectOption[] = [];
    public institutionOptions: SelectOption[] = [];

    private subs: Subscription[] = [];

    constructor(
        private institutionService: InstitutionService, 
        private route: Router,
        private cdRef: ChangeDetectorRef,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle(`Selección de institución`);
        this.gaService.pageView(`${window.location.href}`, `Selección de institución`);

        this.institutionService.institutionList.subscribe((value) => {
            if (value.length == 0 && this.route.url == "/select-institution") {
                this.institutionService.getInstitutions();
                return;
            }

            // Clone array
            this.institutionOptions = JSON.parse(JSON.stringify(value));

            // If there is only one institution, select it
            if ( this.institutionOptions.length === 1 ) {
                this.institutionService.selectInstitution(this.institutionOptions[0].value);
            } else {
                this.focusSelector();
            }

            // Select the first one
            if (this.institutionOptions[0]) {
                this.form.controls.institution.setValue(
                    this.institutionOptions[0].value, { emitEvent: false }
                );
            }
        });

        this.form.controls['institution'].valueChanges.subscribe((value) => {
            this.submit();
        })
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    submit(): void {
        if (this.form.valid) {
            this.institutionService.selectInstitution(
                this.form.getRawValue().institution
            );
        }
    }

    focusSelector() {
        this.cdRef.detectChanges();
        this.institutionField.openSelectAndFocusFilter();
    }
}

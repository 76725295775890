<div class="container-fluid" *ngIf="formReady">
  <div class="profile">
    <div class="main">
      <app-form-detail
        *ngIf="medicineInfo"
        [title]="forms['general'].form.value.general.name"
        [subTitle]="forms['general'].form.value.general.masterCode"
        [typeDefaultImg]="3"
        [formChanged]="formChanged"
        [collapsedLayout]="collapsedProfile"
        (collapseStatus)="collapsedProfile = $event"
        (saveChanges)="updateMedicineEdit()"
        [canSave]="Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE)"
        [disabledAllActions]="!Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE)"
        [section]="MainSection.Vademecum"
        [hideImageActions]="!Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE)"
        [isVademecum]="true"
        [avatarZoomable]="true"
      >
      </app-form-detail>

      <div class="danger-group" 
        *ngIf="medicineInfo.general.dangerGroup"
        [ngClass]="{'collapsed': collapsedProfile}"
      >
        <div class="title" *ngIf="!collapsedProfile">Medicamento peligroso</div>

        <ng-container [ngSwitch]="medicineInfo.general.dangerGroup">
          <div *ngSwitchCase="1">
            <img
              class="img-icon" 
              src="assets/images/meidcamentos-peligrosos/grupo1-64.png"
            />
            <div class="group" *ngIf="!collapsedProfile">Grupo 1</div>
            <div class="group-type" *ngIf="!collapsedProfile">Antineoplásico</div>
          </div>
          <div *ngSwitchCase="2">
            <img
              class="img-icon" 
              src="assets/images/meidcamentos-peligrosos/grupo2-64.png"
            />
            <div class="group" *ngIf="!collapsedProfile">Grupo 2</div>
            <div class="group-type" *ngIf="!collapsedProfile">No antineoplásico</div>
          </div>
          <div *ngSwitchCase="3">
            <img
              class="img-icon" 
              src="assets/images/meidcamentos-peligrosos/grupo3-64.png"
            />
            <div class="group" *ngIf="!collapsedProfile">Grupo 3</div>
            <div class="group-type" *ngIf="!collapsedProfile">Riesgo para el proceso reproductivo</div>
          </div>
        </ng-container>
      </div>

      <button class="danger-group-doc" 
        *ngIf="medicineInfo.general.dangerGroup"
        [ngClass]="{'collapsed': collapsedProfile}"
        (click)="openDangerGroupDoc()"
      >
        <mat-icon>
          file_open
        </mat-icon>

        <div class="title" *ngIf="!collapsedProfile">
          Medidas de protección para su manejo
        </div>
      </button>
    </div>

    <div class="color-legend" *ngIf="selectedIndex === 0">
      <app-color-legend 
        class="legend" 
        [items]="colorLegendItems" 
        [legendPosition]="'top'"
      >
      </app-color-legend>
    </div>
  </div>
  <div class="content" [ngClass]="{'full-width': collapsedProfile}">
    <mat-tab-group
			style="height: 100%;"
			animationDuration="0ms"
			[selectedIndex]="selectedTabIndex"
			(selectedIndexChange)="tabIndexChange($event)"
		>
      <ng-container *ngFor="let tab of tabs; let i = index">
        <mat-tab
          *ngIf="tab.hidden == undefined || tab.hidden().length > 0"
          [disabled]="!isEdit && tab.form !== 'general'">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="forms[tab.form] && forms[tab.form].form.invalid && forms[tab.form].form.touched"
            color="warn"
            class="example-tab-icon me-2">
            info
            </mat-icon>
            {{ tab.label }}
          </ng-template>
          <ng-container [ngSwitch]="tab.form">
            <!-- General -->
            <ng-container *ngSwitchCase="'general'">
              <app-medicine-edit-general
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [formChanged]="formChanged"
                [dangerGroup]="medicineInfo.general.dangerGroup"
                (save)="updateMedicineEdit()"
              >
              </app-medicine-edit-general>
            </ng-container>

            <!-- Notes -->
            <ng-container *ngSwitchCase="'notes'">
              <app-medicine-edit-notes
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [formChanged]="formChanged"
                (save)="updateMedicineEdit()"
              >
              </app-medicine-edit-notes>
            </ng-container>

            <!-- Epigraphs -->
            <ng-container *ngSwitchCase="'epigraphs'">
              <app-medicine-edit-epigraphs
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [formChanged]="formChanged"
                (save)="updateMedicineEdit()"
              >
              </app-medicine-edit-epigraphs>
            </ng-container>

            <!-- Interactions -->
            <ng-container *ngSwitchCase="'interactions'">
              <app-medicine-edit-interactions
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [formChanged]="formChanged"
                (save)="updateMedicineEdit()"
              >
              </app-medicine-edit-interactions>
            </ng-container>

            <!-- Pharmacy -->
            <ng-container *ngSwitchCase="'farmacia'">
              <app-medicine-edit-pharmacy
                [farmacias]="farmacias"
                [medicineList]="medicineList"
                [tiposInventario]="tiposInventario"
                [formChanged]="formChanged"
                (save)="updateMedicineEdit()"
                (onFormChanged)="formChanged = $event"
              >
              </app-medicine-edit-pharmacy>
            </ng-container>

            <!-- Robot -->
            <ng-container *ngSwitchCase="'robot'">
              <app-medicine-edit-robot
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [formChanged]="formChanged"
                (save)="updateMedicineEdit()"
              >
              </app-medicine-edit-robot>
            </ng-container>
          </ng-container>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</div>

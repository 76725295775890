import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, forkJoin, skipWhile } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '@json/src/environments/environment';

import { CallsService } from '@services/api/calls.service';
import { LoadingService } from '@services/loading.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { InstitutionService } from '@services/institution.service';
import { ImageService } from '@services/image/image.service';
import { RoleManagerService } from '@services/role-manager.service';

import { SelectOption } from '@interfaces/input-select-option.interface';
import { User } from '@interfaces/user/user.interface';
import { InstitutionByCA, UserQlikInstitution } from '@interfaces/institution/institution.interface';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';

import { UserRole } from '@json/src/app/enums/user-role';

import { PHARMA_ROLES, QUANTUM_ROLES } from '@constants/roles';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

import { UserComponent } from './user/user.component';

import { UserType } from '@enums/user-type';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-bi',
  templateUrl: './bi.component.html',
  styleUrls: ['./bi.component.scss']
})
export class BiComponent {

  UserRole = UserRole;

  readonly dialog = inject(MatDialog);

  public form: FormGroup;

  public users: SelectOption[] = [];
  public biUsers: SelectOption[] = [];
  public filteredUsers: SelectOption[] = [];
  public filteredBiUsers: SelectOption[] = [];

  public provinces: SelectOption[] = [];
  public filteredProvinces: SelectOption[] = this.provinces;

  public institutions: SelectOption[] = [];
  public filteredInstitutions: SelectOption[] = this.institutions;

  loadingUsers: boolean = false;

  userFiltersOptions: FilterSelect[] = [];
  usersFilter = new FormControl(null);
  usersSearch = new FormControl(null);
  provincesSearch = new FormControl(null);
  institutionsSearch = new FormControl(null);

  constructor(
    private calls: CallsService,
    private loadingService: LoadingService,
    public imageService: ImageService,
    private institutionService: InstitutionService,
    private institutionsService: InstitutionsService,
    private roleManager: RoleManagerService,
    private modalService: ModalService,
    protected router: Router,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      user: new FormControl(null),
      province: new FormControl(null),
    });

    this.loadingUsers = true;
    this.loadingService.start();

    this.institutionService.getInstitutions().then(() => {
      this.institutions = this.institutionService.institutionList.getValue();
      this.filteredInstitutions = this.institutions;
    });

    this.institutionsService.getQlikInstitutionListByCA().then((data: InstitutionByCA[]) => {
      this.provinces = data.map((institution: InstitutionByCA) => {
        return {
          label: institution.Key,
          value: institution.Value,
        }
      });
      this.filteredProvinces = this.provinces;

      this.getUsers();
    });

    if ( this.roleManager.isPharma() ) {
      this.userFiltersOptions = [
        {
          name: 'Rol',
          filter: PHARMA_ROLES.map((role) => { return { id: role.label.toString(), value: role.label } })
        }
      ]
    } else {
      this.userFiltersOptions = [
        {
          name: 'Rol',
          filter: QUANTUM_ROLES.map((role) => { return { id: role.label.toString(), value: role.label } })
        }
      ]
    }

    this.usersFilter.valueChanges
    .pipe(skipWhile(() => this.loadingUsers))
    .subscribe((filters: any) => {
      this.filterUsers(filters, this.usersSearch.value || '');
    });
    this.usersSearch.valueChanges
    .pipe(skipWhile(() => this.loadingUsers))
    .subscribe((query: any) => {
      this.filterUsers(this.usersFilter.value || [], query);
    });
    this.provincesSearch.valueChanges
    .pipe(skipWhile(() => this.loadingUsers))
    .subscribe((query: any) => {
      this.filterProvinces(query);
    });
    this.institutionsSearch.valueChanges
    .pipe(skipWhile(() => this.loadingUsers))
    .subscribe((query: any) => {
      this.filterInstitutions(query);
    });
  }

  async getUsers() {
    this.loadingUsers = true;
    this.loadingService.start();

    return new Promise((resolve, reject) => {
      this.institutionsService.getQlikUsers().then((data: any) => {
        if ( data.BiUsers ) {
          this.biUsers = data.BiUsers.map((user: any) => {
            user.institutions = user.institutions.map((inst: any) => inst.InstitutionId);

            return {
              label: user.nam,
              value: {...user, userType: UserType.BIExclusive},
              otherData: user.biAccess ? 'checked' : ''
            }
          });

          this.filteredBiUsers = this.biUsers;
        }
      });

      this.calls.getUserDisplayList().subscribe({
        next: (data) => {

          this.users = data.map((user: User) => {
            return {
              label: user.nam,
              value: {...user, userType: UserType.Quantum},
              otherData: user.biAccess ? 'checked' : ''
            }
          })

          data.forEach((user: User) => {
            const formatedID = this.imageService.formatNumberImage(Number(user.id));
            const imageUrl = `${environment.urlBack}resources/assets/pics/users/e00/${formatedID}.jpg`;
            user.imagePath = imageUrl;

            this.institutionsService.getQlikUserInstitutions(user.id, UserType.Quantum).then((data: UserQlikInstitution) => {
              user.biAccess = data?.access;
              user.institutions = data.insts.map((inst) => inst.Id);

              this.users.find((item: SelectOption) => item.value.id === user.id)!.otherData = user.biAccess ? 'checked' : '';
              this.users.find((item: SelectOption) => item.value.id === user.id)!.value.institutions = user.institutions;
            });
          });

          this.filteredUsers = this.users;

          resolve(true);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        },
        complete: () => {
          this.loadingUsers = false;
          this.loadingService.stop();
        }
      });
    });
  }

  getUserRole(role: any) {
    return UserRole[role];
  }

  getDefaulImg(user: SelectOption) {
    user.value.imagePath = user.value.gnd == 'M' ? '/assets/pics/male_nurse.svg' : '/assets/pics/female_nurse.svg';
  }

  userSelected() {
    return !!this.form.get('user')?.value;
  }

  isUserSelected(user: SelectOption): boolean {
    return this.form.get('user')?.value === user.value;
  }

  setUser(user: SelectOption | null) {
    this.form.patchValue({ user: user?.value, province: undefined });

    this.configureProvincesSelect();
  }

  provinceSelected() {
    return !!this.form.get('province')?.value;
  }

  isProvinceSelected(province: SelectOption): boolean {
    return this.form.get('province')?.value === province.value;
  }

  setProvince(province: SelectOption | null) {
    this.form.patchValue({ province: province?.value });

    this.institutions = province?.value.map((item: any) => {
      return {
        label: item.Name,
        value: item.Id
      }
    });
    this.filteredInstitutions = this.institutions;

    this.configureInstittionsSelect();
    this.filterInstitutions(this.institutionsSearch.value || '');
  }

  toggleUserCheck(event: any, user: SelectOption) {
    event && event.stopPropagation();

    if ( user.value.biAccess || (!user.value.biAccess && user.value.institutions.length) ) {
      user.otherData = user.otherData === 'checked' ? '' : 'checked';
      user.value.biAccess = user.otherData === 'checked';
      this.institutionsService.toggleQlikAccess(user.value.id, user.otherData === 'checked', user.value.userType);
    } else {
      this.modalService.openModalInfo('El usuario no tiene instituciones asignadas.', 'Usuario sin instituciones');
    }

  }
  toggleProvinceCheck(event: any, province: SelectOption) {
    event.stopPropagation();
    province.otherData = province.otherData === 'checked' ? '' : 'checked';

    if ( province.otherData === 'checked' ) {
      this.addInstitutionsToUser(province.value.map((item: any) => item.Id));
    } else {
      this.removeInstitutionsFromUser(province.value.map((item: any) => item.Id));
    }

    this.configureInstittionsSelect();
  }
  toggleInstitutionCheck(event: any, institution: SelectOption) {
    event.stopPropagation();
    institution.otherData = institution.otherData === 'checked' ? '' : 'checked';

    if ( institution.otherData === 'checked' ) {
      this.addInstitutionsToUser([institution.value]);
    } else {
      this.removeInstitutionsFromUser([institution.value]);
    }

    this.configureProvincesSelect();
    this.configureInstittionsSelect();
  }

  configureProvincesSelect() {
    // Configure province select
    this.provinces.forEach((province: SelectOption) => {
      if ( this.form.get('user')?.value.institutions?.some((id: number) => province.value.map((item: any) => item.Id).includes(id)) ) {
        if ( province.value.every((item: any) => this.form.get('user')?.value.institutions.includes(item.Id)) ) {
          province.otherData = 'checked';
        } else {
          province.otherData = 'indeterminate';
        }
      } else {
        province.otherData = '';
      }
    });
  }
  configureInstittionsSelect() {
    // Configure institution select
    this.institutions.forEach((institution: SelectOption) => {
      if ( this.form.get('user')?.value.institutions?.includes(institution.value) ) {
        institution.otherData = 'checked';
      } else {
        institution.otherData = '';
      }
    });
  }

  addInstitutionsToUser(institutionIds: number[]) {
    const user = this.form.get('user')?.value;
    user.institutions = [...new Set([...this.form.get('user')?.value.institutions, ...institutionIds])]

    this.form.patchValue({ user });

    this.institutionsService.saveQlikUserInstitutions(user.id, user.institutions, user.userType);
  }
  removeInstitutionsFromUser(institutionIds: number[]) {
    const user = this.form.get('user')?.value;
    user.institutions = [...new Set([...this.form.get('user')?.value.institutions.filter((inst: any) => !institutionIds.includes(inst))])]

    if ( !user.institutions.length && user.biAccess ) {
      const originalUser = 
        this.users.find((item: SelectOption) => item.value.id === user.id && item.value.nam === user.nam) || 
        this.biUsers.find((item: SelectOption) => item.value.id === user.id && item.value.nam === user.nam);

      originalUser && this.toggleUserCheck(null, originalUser);

      this.modalService.openModalInfo('El usuario no puede tener acceso a BI si no tiene ninguna institución asignada.', 'Deshabilitado acceso a BI');
    }

    this.form.patchValue({ user });

    this.institutionsService.saveQlikUserInstitutions(user.id, user.institutions, user.userType);
  }

  filterUsers(filters: any[], query: string) {
    /*
     * Filter patients by selected filters
     */
    const adminFilter = filters.find((item: FilterOption) => item.id === 'Admin') ? true : false;
    const admFilter = filters.find((item: FilterOption) => item.id === 'Adm') ? true : false;
    const incontinenciaFilter = filters.find((item: FilterOption) => item.id === 'Incontinencia') ? true : false;
    const medicacionFilter = filters.find((item: FilterOption) => item.id === 'Medicacion') ? true : false;
    const reportesFilter = filters.find((item: FilterOption) => item.id === 'Reportes') ? true : false;
    const revisorFilter = filters.find((item: FilterOption) => item.id === 'Revisor') ? true : false;
    const titularFilter = filters.find((item: FilterOption) => item.id === 'Titular') ? true : false;
    const farmaceuticoFilter = filters.find((item: FilterOption) => item.id === 'Farmaceutico') ? true : false;
    const tecnicoFilter = filters.find((item: FilterOption) => item.id === 'Tecnico') ? true : false;

    const adminUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Admin));
    const admUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Adm));
    const incontinenciaUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Incontinencia));
    const medicacionUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Medicacion));
    const reportesUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Reportes));
    const revisorUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Revisor));
    const titularUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Titular));
    const farmaceuticoUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Farmaceutico));
    const tecnicoUsers: SelectOption[] = this.users.filter((user: SelectOption) => user.value.roles.includes(UserRole.Tecnico));

    if ( adminFilter || admFilter || incontinenciaFilter || 
         medicacionFilter || reportesFilter || revisorFilter ||
         titularFilter || farmaceuticoFilter || tecnicoFilter
        ) {
        this.filteredUsers = [];

        if ( adminFilter ) 
            this.filteredUsers = [...new Set([...this.filteredUsers, ...adminUsers])];
        if ( admFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...admUsers])];
        if ( incontinenciaFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...incontinenciaUsers])];
        if ( medicacionFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...medicacionUsers])];
        if ( reportesFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...reportesUsers])];
        if ( revisorFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...revisorUsers])];
        if ( titularFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...titularUsers])];
        if ( farmaceuticoFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...farmaceuticoUsers])];
        if ( tecnicoFilter )
            this.filteredUsers = [...new Set([...this.filteredUsers, ...tecnicoUsers])];
    } else {
        this.filteredUsers = this.users;
    }

    this.filteredUsers = this.filteredUsers.filter((user: SelectOption) =>
      (query.trim().length == 0 ? true : Object.values(user).some((val) => 
        query.trim().length > 0 && 
        val
          ?.toString()
          .toLowerCase()
          .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
          .includes(query.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
      ))
    )

    this.filteredBiUsers = this.biUsers.filter((user: SelectOption) =>
      (query.trim().length == 0 ? true : Object.values(user).some((val) => 
        query.trim().length > 0 && 
        val
          ?.toString()
          .toLowerCase()
          .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
          .includes(query.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
      ))
    )
  }
  filterProvinces(query: string) {
    this.filteredProvinces = this.provinces.filter((province: SelectOption) =>
      (query.trim().length == 0 ? true : Object.values(province).some((val) => 
        query.trim().length > 0 && 
        val
          ?.toString()
          .toLowerCase()
          .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
          .includes(query.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
      ))
    )
  }
  filterInstitutions(query: string) {
    this.filteredInstitutions = this.institutions.filter((institution: SelectOption) =>
      (query.trim().length == 0 ? true : Object.values(institution).some((val) => 
        query.trim().length > 0 && 
        val
          ?.toString()
          .toLowerCase()
          .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
          .includes(query.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
      ))
    )
  }

  newUser() {
    const dialogRef = this.dialog.open(UserComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.getUsers();
      }
    });
  }

  editBiUser(event: any, user: SelectOption) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(UserComponent, {
      data: {user: user.value},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.getUsers();
      }
    });
  }

  tabIndexChange(event: any) {
    switch (event) {
      case 0:
        this.setUser(this.filteredUsers[0]);
      break;
      case 1: 
        this.setUser(this.filteredBiUsers[0]);
      break;
    }
  }
}
